export const colunas = [
  {
    text: "Cliente",
    value: "nomeCliente",
  },
  {
    text: "Projeto",
    value: "nomeProjeto",
  },
  {
    text: "Número de devices",
    value: "qtdDevices",
  },
  {
    text: "Número de devices por usuário",
    value: "qtdDevicesPorUsuario",
  },
  {
    text: "Usuário",
    value: "usuario",
  },
  {
    text: "Senha",
    value: "senha",
  },

  {
    text: "URL",
    value: "proxyURL",
  },
  {
    text: "Ativo",
    value: "ativo",
    align: "center",
    custom: true,
  },
  {
    text: "Token",
    value: "apiToken",
    custom: true,
    align: "end",
    sortable: false,
  },
];
